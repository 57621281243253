import styles from './index.module.scss'
interface IProps {
    loading: boolean,
    onClick: () => void
}
function Loading(props: IProps) {
    const { loading, onClick } = props
    return (
        <div className={styles.wrapper} style={{ opacity: loading ? 1 : 0, pointerEvents: loading ? 'all' : 'none' }}>
            <div className={styles.rubikLoaderWrap}>
                <div className={styles.rubikLoader}></div>
                <div className={styles.titleWrap}>
                    {'资源加载中'.split("").map((char, index) => (
                        <div key={index} className={styles.title} style={{ animationDelay: `${index * 0.1}s` }}>
                            {char}
                        </div>
                    ))}
                </div>
                <div className={styles.btn} onClick={onClick}>直接进入</div>
            </div>
        </div>
    );
}

export default Loading;