import styles from './index.module.scss'; // 导入样式文件

enum Direction {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical'
}

interface IProps {
    value: number;
    onChaneg: (value: any) => void;
    style?: any;
    step?: number;
    direction?: "horizontal" | "vertical";
    bgColor?: string;
    color?: string;
}

const ProgressBar = (props: IProps) => {
    const { bgColor = "", color = "", value = 0, onChaneg = () => { }, style = {}, step = 0.01, direction = Direction.HORIZONTAL } = props
    const { width, height } = style
    return (
        <div className={styles.app} style={{ transform: direction === Direction.VERTICAL ? 'rotate(-90deg)' : 'none', }}>
            <div style={{ backgroundColor: bgColor, width, height, borderRadius: (height / 2) }} className={styles.ProgressBarWrap}>
                <div className={styles.currentColor} style={{ width: `${Math.min((width - 6), Math.max(7, Math.round(width * value)))}px`, backgroundColor: color, height }}></div>
            </div>
            <input
                type="range"
                style={{ width, height }}
                className={styles.slider}
                min={0}
                max={1}
                step={step}
                value={value}
                onChange={onChaneg}
            />
        </div>

    );
};

export default ProgressBar;