import { useState } from 'react';
import { useSpring, useSprings, useSpringRef, animated } from '@react-spring/web'
import clsx from 'clsx';
import styles from './index.module.scss';
interface IProps {
    visible: boolean,

};
function Drawer(props: IProps) {
    const { visible = false } = props;
    const [visible2, setVisible2] = useState(false)
    const data = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    const api = useSpringRef();
    const springs = useSpring({
        ref: api,
        from: { right: '-65vw' },
    });
    const itemTo = (i: number) => ({
        scale: 1,
        delay: (i * 100) + 200,
    })
    const itemFrom = (_i: number) => ({ scale: 0 })
    const [itemSpring, itemSpringApi] = useSprings(data.length, i => ({
        from: itemFrom(i),
        config: {
            mass: 1,
            friction: 8,
            tension: 100,
        },
    }))
    const closeDrawer = (event: { target: any; currentTarget: any; }) => {
        if (event.target === event.currentTarget) {
            setVisible2(false)
            api.start({
                to: {
                    right: '-65vw'
                },
                config: { duration: 200 },
            })
            itemSpringApi.start((i: number) => ({
                scale: 0
            }))
        }
    }
    const openDrawer = () => {
        setVisible2(true)
        api.start({
            to: {
                right: '0vw'
            },
            config: { duration: 200 },
        })
        itemSpringApi.start((i: number) => ({
            ...itemTo(i),
        }))
    }
    return (
        <>
            <div className={clsx(styles.drawerWrap, visible2 && styles.drawerWrapShow)} onClick={closeDrawer} >
                <animated.div className={styles.drawer} style={{ ...springs }}>
                    <div className={styles.drawerHeader}>header</div>
                    <div className={styles.content}>
                        {itemSpring.map((item, i) => <animated.div className={styles.item} style={{ ...item }} key={i}>
                            {data[i]}
                        </animated.div>)}
                    </div>
                </animated.div>
            </div>
            {/* <button className={styles.button} onClick={openDrawer}>+</button> */}
        </>
    );
};

export default Drawer;