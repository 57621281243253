import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { songList, SongList } from './songList';
import Switch from '../Switch';
import AudioPlayer from 'react-audio-player';
import AudioSpectrum from 'react-audio-spectrum';
import ProgressBar from '../ProgressBar';
import styles from './index.module.scss';

enum Loop {
    ALL_LOOP = 'all_loop',
    RANDOM_LOOP = 'random_loop',
    SINGLE_LOOP = 'single_all'
}
const MusicPlayer = () => {
    const audioPlayer = useRef<HTMLAudioElement | null>(null);
    const songPicRef = useRef<HTMLDivElement | null>(null);
    const audioPlayerRef = useRef<NodeJS.Timeout | null>(null)
    const songProgressOnChangeRef = useRef<NodeJS.Timeout | null>(null)
    const [audioPlayerInit, setAudioPlayerInit] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false);
    const [ispc, setIspc] = useState(false);
    const [totalTime, setTotalTime] = useState(0);
    const [progressBarValue, setProgressBarValue] = useState(0);
    const [isShowMusic, setIsShowMusic] = useState(true)
    const [isShowSpectrum, setIsShowSpectrum] = useState(true)
    const [isVolumeShow, setIsVolumeShow] = useState(false)
    const [isSongListShow, setIsSongListShow] = useState(false)
    const [currentLoop, setCurrentLoop] = useState(Loop.ALL_LOOP)
    const [volume, setVolume] = useState(0.9);
    const [allSong] = useState<SongList[]>(songList)
    const [randomSongList, setRandomSongList] = useState<SongList[]>([])
    const [currentRandomSongIndex, setCurrentRandomSongIndex] = useState(0)
    const [allSongTotal] = useState(songList.length - 1)
    const [currentSongIndex, setCurrentSongIndex] = useState(0)
    const [currentSong, setCurrentSong] = useState(allSong[0])
    const [hoverSongindex, setHoverSongindex] = useState<number | null>(null)
    const loopUrl = useMemo(() => {
        switch (currentLoop) {
            case Loop.ALL_LOOP:
                return 'loopall.svg'
            case Loop.RANDOM_LOOP:
                return 'randomloop.svg'
            case Loop.SINGLE_LOOP:
                return 'singleloop.svg'

        }
    }, [currentLoop])
    const isPc = () => {
        try {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return false
            } else {
                return true
            }
        } catch (error) {
            return false
        }
    }
    useEffect(() => {
        const _isPc = isPc()
        !_isPc && setIsShowSpectrum(false)
        setIspc(_isPc)
        audioPlayer.current = (document.getElementById('audio-element')) as HTMLAudioElement
    }, []);
    useEffect(() => {
        let progressBarTimer: NodeJS.Timeout | null = null
        if (audioPlayer.current && isPlaying) {
            progressBarTimer = setTimeout(() => {
                const durationTime = Math.ceil((audioPlayer.current as HTMLAudioElement).duration)
                const currentTimeValue = Math.ceil((audioPlayer.current as HTMLAudioElement).currentTime)
                const progress = (currentTimeValue / durationTime).toFixed(4)
                if (durationTime !== totalTime) {
                    setTotalTime(durationTime)
                }
                setProgressBarValue(Number(progress) || 0)
            }, 1000);
        }
        return () => clearTimeout(progressBarTimer as NodeJS.Timeout)
    }, [progressBarValue, isPlaying])
    useEffect(() => {
        // 切换歌曲 旋转位置重置
        (songPicRef.current as HTMLDivElement).className = styles.songPic;
        setTimeout(() => {
            (songPicRef.current as HTMLDivElement).className = `${styles.songPic} ${styles.songPicAni}`;
        }, 300);
    }, [currentSongIndex])
    // 替换新的进度条
    const songProgressOnChange = (event: { target: { value: any; }; }) => {
        const songProgress = event.target.value;
        if (audioPlayer.current) {
            clearTimeout(songProgressOnChangeRef.current as NodeJS.Timeout);
            const durationTime = Math.ceil((audioPlayer.current as HTMLAudioElement).duration)
            setProgressBarValue(songProgress);
            songProgressOnChangeRef.current = setTimeout(() => {
                if (!isPlaying) {
                    (audioPlayer.current as HTMLAudioElement).play();
                }
                (audioPlayer.current as HTMLAudioElement).currentTime = Math.floor(songProgress * durationTime);
            }, 600);
        }


    };
    // 正常
    const showMusicBox = () => {
        if (!audioPlayerInit && !isPlaying) {
            if (audioPlayer.current) {
                (audioPlayer.current as HTMLAudioElement).play();
            }
        }
        setIsShowMusic(v => !v)
    }
    // 正常
    const handlePlayPause = () => {
        if (!audioPlayerInit) setAudioPlayerInit(true)
        if (audioPlayer.current) {
            if (audioPlayerRef.current) return
            if (isPlaying) {
                audioPlayer.current.pause();
            } else {
                audioPlayer.current.play();
            }
            setIsPlaying(!isPlaying);
            audioPlayerRef.current = setTimeout(() => {
                clearTimeout(audioPlayerRef.current as NodeJS.Timeout)
                audioPlayerRef.current = null
            }, 600);
        }
    };
    // 替换新的进度条
    const handleVolumeChange = (event: { target: { value: any; }; }) => {
        const newVolume = event.target.value;
        if (audioPlayer.current) {
            setVolume(newVolume);
        }
    };
    // 切换播放方式 正常
    const onChangeLoop = () => {
        let loopValue = Loop.RANDOM_LOOP
        switch (currentLoop) {
            case Loop.ALL_LOOP:
                loopValue = Loop.RANDOM_LOOP
                RandomSong()
                break
            case Loop.RANDOM_LOOP:
                loopValue = Loop.SINGLE_LOOP
                setRandomSongList([])
                break
            case Loop.SINGLE_LOOP:
                loopValue = Loop.ALL_LOOP
                break
        }
        setCurrentLoop(loopValue)
    }
    // 切换歌曲 正常
    const switchSongs = (index: number) => {
        if (audioPlayerRef.current) return
        if (!audioPlayerInit) setAudioPlayerInit(true);
        (audioPlayer.current as HTMLAudioElement).pause();
        setProgressBarValue(0)
        setCurrentSong(currentLoop === Loop.RANDOM_LOOP ? randomSongList[index] : allSong[index])
        if (currentLoop === Loop.RANDOM_LOOP) {
            setCurrentRandomSongIndex(index)
            const currentIndex = allSong.findIndex(item => item.id === randomSongList[index].id);
            setCurrentSongIndex(currentIndex)
        } else {
            setCurrentSongIndex(index)
        }
        if (audioPlayer.current) {
            setTimeout(() => {
                (audioPlayer.current as HTMLAudioElement).play();
            }, 300);
        }
        audioPlayerRef.current = setTimeout(() => {
            clearTimeout(audioPlayerRef.current as NodeJS.Timeout)
            audioPlayerRef.current = null
        }, 800);
    }
    //  上一首 正常
    const preOnClick = () => {
        if (currentLoop === Loop.SINGLE_LOOP) return
        let index
        if (currentLoop === Loop.RANDOM_LOOP) {
            index = currentRandomSongIndex - 1
            if (currentRandomSongIndex === 0) {
                return
            }

        } else {
            index = currentSongIndex - 1
            if (currentSongIndex === 0) {
                return
            }
        }
        switchSongs(index)
    }
    // 下一首 正常
    const nextOnClick = () => {
        if (currentLoop === Loop.SINGLE_LOOP) return
        let index
        if (currentLoop === Loop.RANDOM_LOOP) {
            index = currentRandomSongIndex + 1
            if (currentRandomSongIndex === randomSongList.length - 1) {
                index = 0
            }

        } else {
            index = currentSongIndex + 1
            if (currentSongIndex === allSongTotal) {
                index = 0
            }
        }
        switchSongs(index)
    }
    // 歌单切歌 正常
    const songOnClick = (index: number) => {
        if (index === currentSongIndex) return
        switchSongs(index)
    }
    // 随机播放 正常
    const RandomSong = () => {
        const randmSongList = [...allSong].sort(() => Math.random() - 0.5)
        const currentIndex = randmSongList.findIndex(item => item.id === currentSong.id);
        setRandomSongList(randmSongList)
        setCurrentRandomSongIndex(currentIndex)
    }
    // 歌单展示
    const songListOnClick = () => {
        setIsSongListShow(v => !v)
    }
    const audioError = (e: any) => {
        if (!audioPlayerInit) return
        setTimeout(() => {
            nextOnClick()
        }, 2000);
    }
    return (
        <div style={{ width: "0px", height: "100vh", backgroundColor: 'transparent', position: 'absolute', zIndex: 9999 }}>
            <div className={styles.musicWrap} style={{ left: isShowMusic ? 0 : -260, bottom: isSongListShow ? '400px' : '120px' }}>
                <div className={styles.playWrap}>
                    <div className={styles.songWrap}>
                        <div ref={songPicRef} className={`${styles.songPic} ${styles.songPicAni}`} style={{ animationPlayState: isPlaying ? 'running' : 'paused' }}>
                            <img src={currentSong.pic} alt="" />
                        </div>
                        <div className={styles.songInfo}>
                            <span className={styles.ellipsisText}>{currentSong.song}</span>
                            <span className={styles.ellipsisText}>{currentSong.singer}</span>
                        </div>
                    </div>
                    <div className={styles.operateWrap}>
                        <div className={styles.operateTop}>
                            <img onClick={() => { setIsVolumeShow(v => !v) }} className={styles.volumeBtn} src={`${process.env.PUBLIC_URL}/images/voice.svg`} alt="" />
                            <div className={styles.progress}>
                                <ProgressBar
                                    style={{
                                        width: 150,
                                        height: 8
                                    }}
                                    bgColor='#3ac9f84c'
                                    color='#33ccff'
                                    step={0.0001}
                                    value={progressBarValue}
                                    onChaneg={songProgressOnChange} />
                            </div>
                            <img onClick={onChangeLoop} src={`${process.env.PUBLIC_URL}/images/${loopUrl}`} className={styles.loop} alt="" />
                        </div>
                        <div className={styles.operateBottom}>
                            <Switch width={20} height={10} isChecked={isShowSpectrum} onChange={(value) => {
                                ispc && setIsShowSpectrum(value)
                            }} />
                            <img onClick={preOnClick} className={styles.pre} src={`${process.env.PUBLIC_URL}/images/next${currentLoop === Loop.SINGLE_LOOP || (currentLoop === Loop.ALL_LOOP && currentSongIndex === 0) ? '_disable' : ''}.svg`} alt="" />
                            <div className={styles.palyOrpauseWrap} onClick={handlePlayPause}>
                                <div className={styles.palyOrpause} style={{ top: isPlaying ? -20 : 0 }}>
                                    <img className={styles.play} src={`${process.env.PUBLIC_URL}/images/play.svg`} alt="" />
                                    <img className={styles.pause} src={`${process.env.PUBLIC_URL}/images/pause.svg`} alt="" />
                                </div>
                            </div>
                            <img onClick={nextOnClick} className={styles.next} src={`${process.env.PUBLIC_URL}/images/next${currentLoop === Loop.SINGLE_LOOP ? '_disable' : ''}.svg`} alt="" />
                            <img onClick={songListOnClick} className={styles.songList} src={`${process.env.PUBLIC_URL}/images/songlist.svg`} alt="" />
                        </div>
                    </div>
                    <div className={`${styles.songListWrap} ${isSongListShow ? '' : styles.songListHidden}`}>
                        <div className={styles.songListContent}>
                            {
                                allSong.map((item, index) => {
                                    return (<div
                                        key={index}
                                        onClick={() => songOnClick(index)}
                                        onMouseEnter={() => { setHoverSongindex(index) }}
                                        onMouseLeave={() => { setHoverSongindex(null) }}
                                        className={`${styles.songItem} ${index === hoverSongindex ? styles.hoverSong : ''}`}
                                        style={{
                                            backgroundColor: currentSongIndex === index ? "#33ccffa2" : "",
                                            color: currentSongIndex === index ? "#FF69B4" : "",
                                            boxShadow: currentSongIndex === index ? 'rgba(51, 204, 255, 0.8) 4px 5px 5px' : ''
                                        }}
                                    >
                                        <span className={styles.songItemIndex}>{index + 1}</span>
                                        <span className={`${styles.ellipsisText} {styles.songInfo}`}>{`${item.song} - ${item.singer}`}</span>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                    {isVolumeShow &&
                        <div className={styles.volumeWrap}>
                            <ProgressBar
                                style={{
                                    width: 80,
                                    height: 6
                                }}
                                bgColor='#3ac9f84c'
                                color='#33ccff'
                                direction="vertical"
                                value={volume}
                                onChaneg={handleVolumeChange}
                            />
                        </div>
                    }
                </div>
                <div className={styles.musicSwitch} onClick={showMusicBox}>
                    <div className={styles.musicBackGround} style={{ animationPlayState: isPlaying ? 'running' : 'paused' }}></div>
                    <div className={styles.musicBorder}>
                        <img src={`${process.env.PUBLIC_URL}/images/musicIcon.svg`} alt="" />
                    </div>
                </div>
                <AudioPlayer listenInterval={1000} volume={volume} id='audio-element' src={currentSong.url} autoPlay crossOrigin="anonymous"
                    onEnded={() => {
                        nextOnClick()
                    }}
                    onPlay={() => {
                        setIsPlaying(true)
                    }}
                    onPause={() => {
                        setIsPlaying(false)
                    }}
                    onError={() => {
                        nextOnClick()
                    }}
                    onListen={(onListen) => {
                        console.log('%c [ onListen ]-324', 'font-size:13px; background:pink; color:#bf2c9f;', onListen)
                    }}
                />
                {ispc && <div className={styles.spectrum} style={{ opacity: isShowSpectrum ? 1 : 0 }}>
                    <AudioSpectrum
                        id="audio-canvas"
                        height={120}
                        width={window.innerWidth}
                        audioId={'audio-element'}
                        capColor={'#33CCFF'}
                        capHeight={3}
                        meterWidth={3}
                        meterCount={512}
                        meterColor={[
                            { stop: 0, color: '#FF69B4' },
                            { stop: 0.5, color: '#8A2BE2' },
                            { stop: 1, color: '#33CCFF' }
                        ]}
                        gap={4}
                    />
                </div>}
            </div>
        </div>
    );
};

export default MusicPlayer;
