import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import dayjs from 'dayjs';
import Loading from '../src/components/Loading';
import NavBar from './components/NavBar';
import Drawer from './components/Drawer';
import Home from '../src/pages/home';
import About from '../src/pages/about';
import MusicBox from './components/MusicBox';
import './app.css';
const App = () => {
  const [startTime] = useState(new Date())
  const [init, setInit] = useState(false)
  const [loadTime, setLoadTime] = useState(0)
  const [scrollDistance, setScrollDistance] = useState(0);
  const handleScroll = () => {
    // 更新滚动距离
    setScrollDistance(window.scrollY);
  };
  const pageLoaded = () => {
    return new Promise(resolve => {
      if (document.readyState === 'complete') {
        resolve('');
      } else {
        document.addEventListener('DOMContentLoaded', resolve);
        window.addEventListener('load', resolve);
      }
    });
  }
  useEffect(() => {
    pageLoaded().then(() => {
      setInit(true)
    }).catch(() => {
      setInit(true)
    })
    // new (window as any).VConsole();
    window.addEventListener('scroll', handleScroll);
    // 清除滚动事件监听器
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (init) {
      const time = dayjs().diff(dayjs(startTime)) || 0
      setLoadTime(time / 1000)
    }
  }, [init])
  return (
    <>
      <Loading loading={!init} onClick={() => { setInit(true) }} />
      <>
        <NavBar top={scrollDistance} />
        <Drawer visible />
        {
          init && <MusicBox />
        }
        <div className="appNav" />
        <Router>
          <Routes>
            <Route path="/" element={<Home time={loadTime} loading={!init} />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Router>
      </>
    </>
  );
};

export default App;
