import React, { useEffect, useState, useRef, useMemo } from 'react';
import get from 'lodash.get';
import Axios from 'axios';
import dayjs from 'dayjs';
import clsx from 'clsx';
import 'dayjs/locale/zh-cn';
import duration from 'dayjs/plugin/duration';
import DarkButton from '../../components/DarkButton';
import styles from './index.module.scss';
const startTime = dayjs('2024-01-01');
const signature = '声音的传播需要时间，我们面对面说着话，我在过去，你在未来...';
enum Theme {
    LIGHT = 'light',
    DARK = 'dark',
};
interface IProps {
    time: number,
    loading: boolean
}
const isDaytime = () => {
    const currentHour = dayjs().hour();
    return currentHour >= 7 && currentHour < 19;
};
function Index(props: IProps) {
    const { time, loading } = props
    dayjs.extend(duration)
    const isDesktopRef = useRef(false);
    const [currentTheme, setCurrentTheme] = useState<Theme>(isDaytime() ? Theme.LIGHT : Theme.DARK);
    // const [currentTheme, setCurrentTheme] = useState<Theme>(Theme.LIGHT);
    const [oneSentence, setOneSentence] = useState('')
    const [currentTime, setCurrentTime] = useState(dayjs());
    const [loadTime, setLoadTime] = useState(0);
    const [signaturetxt, setSignaturetxt] = useState('');
    const [timeProgress, setTimeProgress] = useState('0');
    const [runTime, setRunTime] = useState('0 天 0 时 0 分 0 秒');
    const [clickIndex, setClickIndex] = useState<number | null>(null)
    const contactList = useMemo(() => ([
        {
            id: 'qq',
            icon: 'QQ.svg',
            qrCode: 'https://qm.qq.com/q/KM1VAbBnk4',
            schemeUrl: 'mqqapi://card/show_pslcard?src_type=internal&version=1&uin=2456155093',
        },
        {
            id: 'wx',
            icon: 'wx.svg',
            qrCode: ''
        },
        {
            id: 'qqmusic',
            icon: 'QQmusic.svg',
            targetUrl: 'https://i2.y.qq.com/n3/other/pages/share/profile_v2/index.html?ADTAG=musicapp&channelId=10036163&encrypt_uin=owvk7w6k7Knqoz**&openinqqmusic=1',
            schemeUrl: 'qqmusic://qq.com/ui/profile?p={"encrypt_uin":"owvk7w6k7Knqoz**","page":""}'
        },
        {
            id: 'bilibili',
            icon: 'bilibili.svg',
            targetUrl: 'https://space.bilibili.com/296503843',
            schemeUrl: 'bilibili://space/296503843?frommodule=H5'
        }
    ]), [currentTheme])
    const getOneSentence = () => {
        Axios({
            method: 'get',
            url: 'https://api.vvhan.com/api/ian',
            params: {
                type: 'json'
            }
        }).then((res) => {
            setOneSentence(get(res, 'data.data.vhan', ''))
        }).catch((err) => {
        })
    };
    const getCurrentSecondsOfDay = () => {
        const now = dayjs();
        const startOfDay = now.startOf('day');
        const secondsElapsed = now.diff(startOfDay, 'second');
        return secondsElapsed;
    };
    const toggleTheme = (value: boolean) => {
        setCurrentTheme(value ? Theme.DARK : Theme.LIGHT);
    }
    const contactOnClick = (value: any, index: number) => {
        setClickIndex(index)
        const { qrCode, targetUrl, schemeUrl } = value
        let url = ''
        if (isDesktopRef.current) {
            if (targetUrl) {
                url = targetUrl
            }
        } else {
            if (schemeUrl) {
                url = targetUrl
            }
        }
        setTimeout(() => {
            window.open(targetUrl)
            setClickIndex(null)
        }, 100);
    }
    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        const isDesktop = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        isDesktopRef.current = isDesktop
        getOneSentence()
        // 获取时间
        const timer = setInterval(() => {
            const currentTime = dayjs();
            const diffDuration = dayjs.duration(currentTime.diff(startTime));

            const days = diffDuration.asDays().toFixed(0);
            const hours = diffDuration.hours().toString().padStart(2, '0');
            const minutes = diffDuration.minutes().toString().padStart(2, '0');
            const seconds = diffDuration.seconds().toString().padStart(2, '0');
            setRunTime(`${days} 天 ${hours} 时 ${minutes} 分 ${seconds} 秒`)
            setCurrentTime(dayjs());
            const secondsProgress = getCurrentSecondsOfDay();
            setTimeProgress(((secondsProgress * 100) / 86400).toFixed(2))
        }, 1000); // 每隔1秒更新一次时间
        return () => {
            clearInterval(timer)
        };
    }, [])
    useEffect(() => {
        const signatureTimer = setInterval(() => {
            if (signature.length > signaturetxt.length) {
                setSignaturetxt(v => v + signature[v.length])
            }
        }, 140)
        return () => clearInterval(signatureTimer)
    }, [signaturetxt])

    return (
        <div className={clsx(styles.app, styles[currentTheme])}>
            <div className={styles.userInfoWrap}>
                {/* <div className={styles.topNav}>
                    <div className={styles.topNavContent}></div>
                </div> */}
                {/* <div className={styles.topbg}>
                    <img className={styles.topbgImage} src={process.env.PUBLIC_URL + '/images/topbg.png'} alt="" />
                </div> */}
                <div className={styles.avatar}>
                    <img className={styles.avatarImage} src={process.env.PUBLIC_URL + '/images/avatar.GIF'} alt="" />
                </div>
                <div className={styles.name}>你好，我是说你好</div>
                <div className={styles.signature}> <span id='text' className={styles.txt}>{signaturetxt}</span> </div>
                <div className={styles.divider}></div>
                <div className={styles.timeWrap}>
                    <div className={styles.timeLft}>
                        <div className={styles.time}>{currentTime.format('HH:mm')}</div>
                        <div className={styles.date}>
                            <span>{currentTime.format('MM-DD')}</span>
                            <span>{currentTime.locale('zh-cn').format('dddd')}</span>
                        </div>
                    </div>
                    <div>
                        <div className={styles.toggleTheme}>
                            <DarkButton loading={loading} darkMode={currentTheme === Theme.DARK} onChangeDarkMode={toggleTheme} />
                        </div>
                    </div>
                </div>
                <div className={styles.contactBox}>
                    {
                        contactList.map((item, index) =>
                        (<div
                            className={styles.contactItem} style={{ boxShadow: index === clickIndex ? 'var(--bx-shadow-int)' : '' }} key={item.id}
                            onClick={() => { contactOnClick(item, index) }}
                        >
                            <img src={`${process.env.PUBLIC_URL}/images/${item.icon}`} alt="" />
                        </div>)
                        )
                    }
                </div>
                <div className={styles.progress}>
                    <div className={styles.progressLine} style={{ width: `${timeProgress}%` }} ></div>
                </div>
            </div>
            {/* <div className={styles.contentWrap}>
                <div className={styles.content}>建设中</div>
            </div> */}
            <div className={styles.footer}>
                <div className={styles.icp}>
                    <img src={process.env.PUBLIC_URL + '/images/icp.png'} alt="" />
                    <a href="https://icp.gov.moe/?keyword=20240110" target="_blank">萌ICP备20240110号</a>
                </div>
                <div className={styles.icp}>
                    <a href="https://beian.miit.gov.cn" target="_blank">赣ICP备2024020243号</a>
                </div>
                <div className={styles.loadTime}>
                    资源加载时间: {time} 秒
                </div>
                <div className={styles.runTime}>
                    已运行: {runTime}
                </div>
            </div>
        </div >
    );
}

export default Index;