import { Link } from 'react-router-dom';
const Index = () => {
  return (
    <>
      <div>abd</div>
      <Link to="/">Go to Home</Link>
    </>
  );
};

export default Index;