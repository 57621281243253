import { useState } from 'react';
import styles from './index.module.scss'; // 导入样式文件

interface IProps {
    width: number;
    height: number;
    isChecked: boolean;
    onChange: (isChecked: boolean) => void
}

const Switch = (props: IProps) => {
    const { width, height, onChange, isChecked } = props

    const toggleSwitch = () => {
        onChange(!isChecked)
    };

    return (
        <div style={{ width, height, borderRadius: (height / 2) }} className={`${styles.switchContainer} ${isChecked ? styles.checked : ''}`} onClick={toggleSwitch}>
            <div style={{ width: (height * 0.7), height: (height * 0.7), transform: isChecked ? `translateX(${width - (height * 0.7) - 2}px)` : 'translateX(2px)' }} className={styles.switch} />
        </div>
    );
};

export default Switch;