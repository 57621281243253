import { useSpring, animated } from '@react-spring/web'
import clsx from 'clsx';
import styles from './index.module.scss';
interface IProps {
    top: number
}
function NavBar(props: IProps) {
    const { top } = props
    return (<div className={`${styles.navbarWrap} ${top > 50 ? styles.navBarHidden : styles.navBarShow}`}>
        <div className={styles.navBar}>
            <div></div>
            <div></div>
        </div>
    </div>);
}

export default NavBar;